<template>
  <table-view :total="total" :query-info.sync="queryInfo" @reload-table="renderTable">
    <template #header>
      <view-search-form ref="searchFormRef" :queryInfo.sync="queryInfo"
                        :tool-list="['partTimeFlag', 'grade', 'college', 'major']"
                        :insert-select-all="['college', 'major']"
                        :filter-keys="['tradeType', 'noTradeTypes', 'payMethod']" @on-search="renderTable(1)" />
      <div class="header-button">
        <el-button @click="exportExcel()" type="success" size="small">
          按条件导出
        </el-button>
        <upload-excel :permit-key="['basic:price:edit']" :loading.sync="loading"
                      :api="$http.importExcelHttp.importPrice"
                      @on-download="$http.exportExcelHttp.exportPriceTemplate()" @on-success="renderTable(1)" />
        <el-button v-permission="['basic:price:edit']" type="primary" size="mini" @click="showDialog()">设置年度费用
        </el-button>

      </div>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column type="index" label="ID" width="50" />
      <el-table-column prop="grade" label="年级" width="100" />
      <el-table-column prop="majorName" label="专业名称" width="200" align="center" />
      <el-table-column prop="lengthOfSchooling" width="100" label="学制" align="center">
        <template v-slot="{ row }">
          {{ row.lengthOfSchooling || row.lengthOfSchooling === 0 ?
          $store.state.systemParam.lengthOfSchoolingType[Number(row.lengthOfSchooling) - 2].paramName : '-'
          }}
        </template>
      </el-table-column>
      <el-table-column prop="collegeName" label="所属院系" width="200" />
      <el-table-column prop="yearOne.allFee" label="第一学年收费" width="150" />
      <el-table-column prop="yearTwo.allFee" label="第二学年收费" width="150" />
      <el-table-column prop="yearThree.allFee" label="第三学年收费" width="150" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" width="100" fixed="right">
        <template v-slot="{ row }">
          <template
            v-if="!$store.state.login.userInfo.collegeId || $store.state.login.userInfo.collegeId === row.collegeId">
            <el-button v-permission="['basic:price:edit']" type="primary" size="mini" @click="showDialog(row)">编辑
            </el-button>
            <!-- 没有id 无法删除 -->
            <!-- <el-button-->
            <!--   size='mini'-->
            <!--   type='danger'-->
            <!--   @click='del(deletePrice, row.id,`${row.collegeName}${row.grade}级的年度费用`)'-->
            <!-- >删除-->
            <!-- </el-button>-->
          </template>
        </template>
      </el-table-column>
    </el-table>
    <add-price ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog" @on-close="renderTable" />
  </table-view>
</template>

<script>
import { getPriceList, deletePrice } from '@/api/price'
import tableView from '@/vue/mixins/table-view'
import AddPrice from '@/views/pages/baseInfo/studyPrice/dialog/addPrice.vue'
import { copyData } from '@/utils/common-js'

export default {
  name: 'price',
  components: {
    AddPrice
  },
  mixins: [tableView],
  data() {
    return {
      queryInfo: {
        partTimeFlag: 0,
        grade: null,
        collegeId: null,
        campusId: null,
        majorId: null
      }
    }
  },
  methods: {
    deletePrice, // 删除api
    // 获取学费列表
    async renderTable(pageNum) {
      await this.getTableData(getPriceList, pageNum)
    },
    showDialog(row) {
      this.visibleDialog = true
      this.$nextTick(() => {
        this.$refs.dialogRef.preTitle = row ? '编辑' : '新增'
        this.$refs.dialogRef.title = '年度费用'
        if (row) {
          copyData(row, this.$refs.dialogRef.parentInfo)
        }
      })
    },
    // 导出
    exportExcel() {
      this.$http.exportExcelHttp.exportYearPrice(this.queryInfo)
    }
  }
}
</script>

<template>
  <el-dialog
    class="add-price-dialog"
    :title="`${preTitle}年度费用`"
    :visible.sync="show"
    :close-on-click-modal="false"
    width="900px"
    :before-close="cancel"
  >
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      label-width="100px"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="年级" prop="grade">
            <el-select v-model="formData.grade" @change="changeGrade">
              <el-option
                v-for="{paramValue} in $store.state.systemParam.grade"
                :label="paramValue"
                :value="paramValue"
                :key="paramValue" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="全日制专业" prop="partTimeFlag">
            <el-select v-model="formData.partTimeFlag" @change="onChangePartTimeFlag">
              <el-option :value="0" label="全日制" />
              <el-option :value="1" label="非全日制" />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="专业名称" prop="majorId">
            <tool-tips-group is-flex tips-content="选择了 【年级】 跟 【是否全日制专业】 后，会加载对应的专业列表。">
              <el-select ref="majorRef" v-model="formData.majorId" :disabled="!majorList.length" filterable
                         @change="changeMajor">
                <el-option
                  v-for="{majorName,id} in majorList"
                  :label="majorName"
                  :value="id"
                  :key="id" />
              </el-select>
            </tool-tips-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属院系">
            <el-select v-model="formData.collegeId" disabled>
              <el-option
                v-for="{collegeName,id} in collegeList"
                :label="collegeName"
                :value="id"
                :key="id" />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider content-position="left">收费项目表 <b
        v-if="this.parentInfo.lengthOfSchooling">({{ this.parentInfo.lengthOfSchooling }}年制)</b></el-divider>
      <el-row>
        <el-col :span="8" v-for="(item, index) in formData.feeList" :key="index"><h3
          style="text-align:center;text-indent: 40px;">第{{ item.academicYear }}学年</h3>
          <el-form-item label="学费" :prop="`feeList.${index}.tuitionFee`"
                        :rules="rules.validateFee">
            <el-input v-model.trim="item.tuitionFee" />
          </el-form-item>
          <el-form-item label="教材费" :prop="`feeList.${index}.textBookFee`"
                        :rules="rules.validateFee">
            <el-input v-model.trim="item.textBookFee" />
          </el-form-item>
          <el-form-item label="保险费" :prop="`feeList.${index}.insuranceFee`"
                        :rules="rules.validateFee">
            <el-input v-model.trim="item.insuranceFee" />
          </el-form-item>
          <el-form-item label="体检费" :prop="`feeList.${index}.phyExamFee`"
                        :rules="rules.validateFee">
            <el-input v-model.trim="item.phyExamFee" />
          </el-form-item>
          <el-form-item label="校服费" :prop="`feeList.${index}.uniformsFee`"
                        :rules="rules.validateFee">
            <el-input v-model.trim="item.uniformsFee" />
          </el-form-item>
          <el-form-item label="其它费用" :prop="`feeList.${index}.otherFee`"
                        :rules="rules.validateFee">
            <el-input v-model.trim="item.otherFee" />
          </el-form-item>
          <el-form-item label="总计">
            <el-input disabled v-model.trim="item.allFee" />
          </el-form-item>
          <el-form-item label="减免住宿费" :prop="`feeList.${index}.roomPriceFreeFlag`">
            <div class="flex">
              <!--              <tool-tips-group tips-content="减免住宿费的费率">-->
              <ElSelect :disabled="!item.roomPriceFreeFlag" v-model="item.roomPercent">
                <ElOption v-for="num in 10" :key="num" :value="num*10" :label="`${num*10}%`" />
              </ElSelect>
              <!--              </tool-tips-group>-->
              <el-switch
                v-model="item.roomPriceFreeFlag"
                class="ml-1 mt-1"
                active-color="var(--color-success)"
                :active-value="1"
                :inactive-value="0"
                @change="$event?'':item.roomPercent=0"
              />
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <!--   不保存备注, 设置了无用 -->
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag" />
      <el-button type="primary" @click="handleSave">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addPrice, getPriceByMajorId } from '@/api/price'
import { getMajorById } from '@/api/major'
import dialog from '@/vue/mixins/dialog'
import { copyData } from '@/utils/common-js'
import campusSchMajorClasses from '@/vue/mixins/campusSchMajorClasses'
import { Decimal } from 'decimal.js'

export default {
  name: 'addPrice',
  mixins: [dialog, campusSchMajorClasses],
  data () {
    return {
      parentInfo: {
        grade: '',
        majorId: '',
        lengthOfSchooling: 0
      },
      // 记录保存成功的次数
      successCount: 0,
      // 记录保存失败的次数
      failCount: 0,
      formData: {
        partTimeFlag: 0,
        grade: '',
        // campusId: '', // 校区
        collegeId: '', // 院系
        majorId: '', // 专业
        remarks: '',
        feeList: []
      },
      rules: {
        grade: [{
          required: true,
          message: '请选择年级',
          trigger: 'blur'
        }],
        majorId: [{
          required: true,
          message: '请选择专业',
          trigger: 'blur'
        }],
        validateFee: [
          {
            required: true,
            message: '请填写费用',
            trigger: 'blur'
          },
          this.$store.state.commonValidate.validatorTwoDecimalPlacesObj
        ]
      }
    }
  },
  watch: {
    'formData.feeList': {
      handler (newFee) {
        newFee.forEach(({
                          tuitionFee,
                          uniformsFee,
                          phyExamFee,
                          textBookFee,
                          insuranceFee,
                          otherFee
                        }, index) => {
          this.formData.feeList[index].allFee = new Decimal(Number(tuitionFee))
            .add(new Decimal(Number(uniformsFee)))
            .add(new Decimal(Number(phyExamFee)))
            .add(new Decimal(Number(textBookFee)))
            .add(new Decimal(Number(insuranceFee)))
            .add(new Decimal(Number(otherFee))).toNumber()
        })
      },
      deep: true
    }
  },
  mounted () {
    this.getCollegeList()
    this.$nextTick(async () => {
      // 存在majorId,为编辑状态
      if (this.parentInfo.majorId) {
        await this.getPriceByMajorId()
        await this.getMajorList({
          grade: this.parentInfo.grade,
          partTimeFlag: this.formData.partTimeFlag,
          collegeId: this.$store.state.login.userInfo.collegeId || null
        })
      } else {
        this.handleFeeList()
        // 手动保存初始参数
        this.setInitData()
      }
    })
  },
  methods: {
    // 根据id获取年度费用
    async getPriceByMajorId () {
      this.loadData.loading = true
      try {
        const res = await getPriceByMajorId({
          grade: this.parentInfo.grade,
          majorId: this.parentInfo.majorId
        })
        Object.keys(this.formData).forEach(key => {
          if (key !== 'feeList') {
            this.formData[key] = res.data[key]
          }
        })
        this.formData.grade = this.parentInfo.grade
        this.formData.feeList = res.data.feeList.sort(
          (a, b) => a.academicYear - b.academicYear
        )
        // 1.先获取专业信息,用于获取 专业的partTimeFlag(是否全日制)
        await this.getMajorById(this.formData.majorId)
        this.handleFeeList(this.parentInfo.lengthOfSchooling)
        this.setInitData()
      } catch (e) {
      } finally {
        this.loadData.loading = false
      }
    },
    // 根据学制(lengthOfSchooling :来至选中的major)加载费用list
    handleFeeList (lengthOfSchooling = 3) {
      const _arr = this.formData.feeList
      const _lengthOfSchooling = Number(lengthOfSchooling)
      // 缴费列表数跟学制年相等，不需要执行此方法
      if (!_lengthOfSchooling || _lengthOfSchooling === _arr.length) return
      if (_arr.length > _lengthOfSchooling) {
        let _num = _arr.length - _lengthOfSchooling
        while (_num) {
          _arr.pop()
          _num--
        }
      } else {
        for (let i = _arr.length + 1; i < lengthOfSchooling + 1; i++) {
          const _data = {
            id: null,
            academicYear: i,
            tuitionFee: '', // 学费
            uniformsFee: '', // 校服/军服费
            phyExamFee: '', // 体检费
            textBookFee: '', // 教材费
            insuranceFee: '', // 保险费
            otherFee: '', // 其他费用
            roomPriceFreeFlag: 0, // 减免住宿 1-是 0-否
            roomPercent: 0, // 减免住宿费用百分比 默认0
            allFee: 0 // 总计
          }
          _arr.push(_data)
        }
      }
      this.formData.feeList = _arr
    },
    // 根据id获取专业信息
    async getMajorById (majorId) {
      try {
        const res = await getMajorById(majorId)
        this.formData.collegeId = res.data.collegeId
        this.formData.partTimeFlag = res.data.partTimeFlag
        this.parentInfo.lengthOfSchooling = res.data.lengthOfSchooling
      } catch (e) {
      }
    },
    // 选择年级
    async changeGrade (grade) {
      this.formData.majorId = ''
      await this.getMajorList({
        grade: grade,
        partTimeFlag: this.formData.partTimeFlag
      })
    },
    // 选择专业
    async changeMajor (majorId) {
      this.formData.collegeId = ''
      await this.getMajorById(majorId)
      this.handleFeeList(this.parentInfo.lengthOfSchooling)
    },
    // 添加年度费用
    handleSave () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const _msg = this.parentInfo.majorId ? '修改' : '新增'
          const _majorInfo = `${this.$refs.majorRef.selectedLabel}(${this.formData.partTimeFlag === 1 ? '非' : ''}全日制)`
          this.$confirm(`是否确定${_msg}年度费用?`, '保存提示').then(async () => {
            const _promise = []
            this.formData.feeList.map(item => {
              _promise.push(() => this.save(item))
            })
            await Promise.all(_promise.map(func => func()))
            const _successMsg = this.failCount ? '保存失败，请稍后重试！' : `${_majorInfo}的年度费用${_msg}成功！`
            this.$message.success(_successMsg)
            this.closed()
          })
        } else {
          this.$message('未通过校验,可能是漏填必填项!')
          return false
        }
      })
    },
    async save (formData) {
      if (this.failCount) return
      try {
        const _data = {
          partTimeFlag: 0,
          grade: '',
          collegeId: '', // 院系
          majorId: '' // 专业
        }
        copyData(this.formData, _data)
        Object.assign(formData, _data)
        await addPrice(formData)
        this.successCount++
      } catch (e) {
        this.failCount++
      }
    },
    onChangePartTimeFlag () {
      this.formData.majorId = ''
      this.formData.collegeId = ''
      this.getMajorList({
        grade: this.formData.grade,
        partTimeFlag: this.formData.partTimeFlag
      })
    }
  }
}
</script>
<style lang="scss">
.add-price-dialog {
  .el-dialog {
    margin-top: 3vh !important;
  }
}
</style>
